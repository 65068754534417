<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('categories', 'edit') || permissionEnabled('categories', 'delete')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :class="l.color ? l.color : 'has-text-grey-light'" v-show="permissionEnabled('categories', l.type)" @click="handleClick(l, id)">
			<div>
				<svg-icon :icon="l.icon"></svg-icon>
				<span>{{ l.name }}</span>
			</div>
		</b-dropdown-item>
	</b-dropdown>
</template>
<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		role: {
			type: String,
			required: true
		},
		active: {
			type: [Boolean, Number],
			required: true
		},
		name: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 1,
						name: 'Edit',
						icon: 'edit',
						type: 'edit'
					},
					{
						id: 5,
						name: 'Remove',
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	methods: {
		handleClick(el, id) {
			const name = el.id
			console.log(name)
			if (name === 1) {
				eventHub.$emit('edit-modal-category', { id })
			} else {
				eventHub.$emit('delete-category', { id })
			}
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled'])
	}
}
</script>
